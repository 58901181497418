/* eslint-disable @wordpress/dependency-group */
// noinspection ES6UnusedImports

import $ from 'jquery';
import backstretch from 'jquery-backstretch';
import { mediaQuery } from '../../helpers';

let initializer = false;
let heroInitializer = false;
let hero = $('.hero-cover');
const header = $('.site-header');
const primaryMenu = document.getElementById('menu-primary-menu');
if(hero.length){
	let hero = $('.hero-cover');
} else {
	let hero = $('.hero');
}
console.log(hero.length);
if(hero.length){
	const heroFromTop = hero.length ? hero.offset().top : 0;
}
const linksBox = $('.hero-links');
const linksBoxTopPosition = linksBox.css('top');
let heroLinksAdjustment = 0;

mediaQuery('min', 1024, function() {
	heroLinksAdjustment = 10;
});

// $('body').css({
// 	backgroundImage: `url(${l18n_js_neher_vars.featured.large})`,
// 	backgroundRepeat: 'no-repeat',
// 	backgroundPosition: 'center 0',
// });
$(document).ready(function() {
	// console.log(l18n_js_neher_vars);

	// $.backstretch([
	// 	[
	// 		{
	// 			width: 3840,
	// 			url: l18n_js_neher_vars.featured.retina,
	// 			pixelRatio: 2,
	// 			alignY: 0,
	// 			scale: 'cover',
	// 		},
	// 		{
	// 			width: 1920,
	// 			url: l18n_js_neher_vars.featured.large,
	// 			pixelRatio: 1,
	// 			alignY: 0,
	// 			scale: 'cover',
	// 		},
	// 		{
	// 			width: 1023,
	// 			url: l18n_js_neher_vars.featured.mobile,
	// 			pixelRatio: 'auto',
	// 			alignY: 0.5,
	// 			scale: 'cover',
	// 		},
	// 	],
	// ]);
	$.backstretch([
		[
			{
				width: 3840,
				url: l18n_js_neher_vars.featured.retina,
				pixelRatio: 2,
				alignY: 0,
				scale: 'cover',
			},
			{
				width: 1920,
				url: l18n_js_neher_vars.featured.large,
				pixelRatio: 1,
				alignY: 0,
				scale: 'cover',
			},
		],
	]);
});
let bgImageSizeHeight = 0;
let bgImageSizeWidth = 0;
let bgImageOffsetLeft = 0;

$(window).on('backstretch.after', function(e, instance, index) {
	const boxHeight = $(instance.$root[0]).height(),
		boxWidth = $(instance.$root[0]).width(),
		ratio =
			instance.$item[0].naturalWidth / instance.$item[0].naturalHeight,
		width = Math.max(boxHeight * ratio, boxWidth);
	bgImageOffsetLeft = -(width - boxWidth) * instance.options.alignX;
	instance.$wrap[0].style.position = 'fixed';
	bgImageSizeHeight = instance.$wrap[0].scrollHeight;
	bgImageSizeWidth = bgImageSizeHeight * 2.2582;
	instance.$item[0].style.left = `${bgImageOffsetLeft}px`;
});

$(window).on('backstretch.resize', function(e, instance, index) {
	instance.$wrap[0].style.maxHeight = `${bgImageSizeHeight}px`;
	// instance.$item[0].style.maxHeight = `${bgImageSizeHeight}px`;
	// instance.$item[0].style.maxWidth = `${bgImageSizeWidth}px`;
	instance.$item[0].style.left = `${bgImageOffsetLeft}px`;
});
$(window).on('scroll', function() {
	if(hero.length){
		const distanceFromTop = $('.site-container').offset().top;
		const scroll = $(window).scrollTop();
		const blurElement = scroll / 50;
		// mediaQuery('min', 768, function() {
		$('.hero-cover').css({
			filter: `blur(${blurElement}px)`,
		});
		// });
	
		if (hero.find('.hero-links').length > 0) {
			if (scroll >= heroFromTop - 87) {
				if (heroInitializer === false) {
					const headerHeight = header.outerHeight(true);
	
					hero.addClass('is-fixed with-hero-links');
	
					linksBox.css({
						top: primaryMenu.offsetHeight + '!important',
					});
	
					heroInitializer = true;
				}
			} else {
				linksBox.css({ top: linksBoxTopPosition });
				hero.removeClass('is-fixed');
				heroInitializer = false;
			}
		}
	
		if (scroll >= distanceFromTop + 10) {
			if (initializer === false) {
				$('body').addClass('is-scroll');
	
				initializer = true;
			}
		} else if (scroll < distanceFromTop + 10) {
			$('body').removeClass('is-scroll');
	
			initializer = false;
		}
	}
});
