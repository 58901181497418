export function mediaQuery(minMaxWidth = 'min', measure = 768, callBack) {
	const mq = window.matchMedia(`(${minMaxWidth}-width: ${measure}px)`);

	function mediaQueryChange(e) {
		if (e.matches) {
			callBack();
		}
	}

	mq.addEventListener('change', mediaQueryChange);
	mediaQueryChange(mq);
}

// export default function valueBetweenMinMax(val, min, max) {
// 	return val > min ? (val < max ? val : max) : min;
// }

// export function mediaQueryChange() {
// 	const mq = window.matchMedia('(min-width: 320px)');
// 	let widthChange;
// 	let windowWidth;
// 	/* JavaScript Media Queries */
// 	if (matchMedia) {
// 		widthChange = (mediaQuery) => {
// 			return mediaQuery.matches;
// 		};
// 		windowWidth = widthChange(mq);
// 	}
//
// 	return windowWidth;
// }
